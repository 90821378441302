import React from "react";
import "../Style/courseItem.css";
import { Link } from "react-router-dom";
import LogoText from "./LogoText";
export default function CourseItem({data}){
    return <div className="flex flexCol courseItemMainDiv rel">
        <Link to={data.url} className="flex flexCol courseItemLinkImg">
            <img src={data.cover} alt={data.title} className="courseImg" />
        </Link>
        <div className="flex flexCol courseInfoDiv">
            <Link to={data.url} className="faFont textClr3 courseName">
                {
                    data.title
                }
            </Link>
            <Link to={"/teacher/"+data.teacherId} className="faFont textSize9 courseTeacher flex alignCenter textClr3">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"></path></svg>
                {
                    data.teacher
                }
            </Link>
            <div className="courseDivider"></div>
            <div className="flex alignCenter justifyBetween courseItemFooter">
                <LogoText data={{logo : <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="10" x2="14" y1="2" y2="2"></line><line x1="12" x2="15" y1="14" y2="11"></line><circle cx="12" cy="14" r="8"></circle></svg>,
                    text : data.duration
                }} />
                <p className="faFont courseItemPrice">
                    {
                        data.price !== 0 ? (data.price.toLocaleString())+" تومان" : "رایگان"
                    }
                </p>
            </div>
        </div>
        {
            data.discount !== 0 && data.discount !== "" && <p className="faFont courseDiscount flex alignCenter">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M109.25 173.25c24.99-24.99 24.99-65.52 0-90.51-24.99-24.99-65.52-24.99-90.51 0-24.99 24.99-24.99 65.52 0 90.51 25 25 65.52 25 90.51 0zm256 165.49c-24.99-24.99-65.52-24.99-90.51 0-24.99 24.99-24.99 65.52 0 90.51 24.99 24.99 65.52 24.99 90.51 0 25-24.99 25-65.51 0-90.51zm-1.94-231.43l-22.62-22.62c-12.5-12.5-32.76-12.5-45.25 0L20.69 359.44c-12.5 12.5-12.5 32.76 0 45.25l22.62 22.62c12.5 12.5 32.76 12.5 45.25 0l274.75-274.75c12.5-12.49 12.5-32.75 0-45.25z"></path></svg>
                {
                    data.discount
                }
            </p>
        }
    </div>
}
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../Style/podcastItem.css";
import { AppContext } from '../App';

export default function PodcastItem({data}){
    const {playerInfo} = useContext(AppContext);
    const [player,setPlayer] = playerInfo;
    function podcastClickHandler(){
        setPlayer(data);
    }
    return <div className="flex flexCol podcastItemDiv">
        <button to={data.url} className="flex flexCol rel" onClick={()=>podcastClickHandler()}>
            <img src={data.cover} alt={data.title} className="podcastImg" />
            <div className="flex flexCenter podcastIcon">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M14 12c0 .74-.4 1.38-1 1.72V22h-2v-8.28c-.6-.35-1-.98-1-1.72 0-1.1.9-2 2-2s2 .9 2 2zm-2-6c-3.31 0-6 2.69-6 6 0 1.74.75 3.31 1.94 4.4l1.42-1.42A3.957 3.957 0 0 1 8 12c0-2.21 1.79-4 4-4s4 1.79 4 4c0 1.19-.53 2.25-1.36 2.98l1.42 1.42A5.957 5.957 0 0 0 18 12c0-3.31-2.69-6-6-6zm0-4C6.48 2 2 6.48 2 12c0 2.85 1.2 5.41 3.11 7.24l1.42-1.42A7.987 7.987 0 0 1 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 2.29-.98 4.36-2.53 5.82l1.42 1.42C20.8 17.41 22 14.85 22 12c0-5.52-4.48-10-10-10z"></path></svg>
            </div>
        </button>
        <div className="flex flexCol podcastItemInfoDiv">
            <Link to={data.url} className="faFont textSize1 textClr3 podcastName">
                {data.title}
            </Link>
            <p className="faFont textSize8 podcastDuration flex alignCenter">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.24 7.76A5.974 5.974 0 0 0 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0a5.99 5.99 0 0 0-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>
                {data.duration}
            </p>
            {
                data.price > 0 ? 
                <>
                {
                    data.discount > 0 ? 
                    <div className="flex alignCenter podcastPriceDiv">
                        <p className="faFont podcastDicountValue textSize8 textClr7">
                            {
                                (+data.price).toLocaleString()
                            }
                        </p>
                        <p className="faFont podcastPriceText textSize1 textClr3 flex alignCenter">
                            {(+data.price - (+data.price * +data.discount) / 100).toLocaleString()}
                            <span className="textSize9 textClr7">تومان</span>
                        </p>
                        <p className="faFont flex alignCenter textSize1 textClr5 podcastDicountPercent">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"></path></svg>
                            {
                                data.discount
                            }
                        </p>
                    </div>
                    :  <p className="faFont podcastRealPriceText textSize1 textClr3 flex alignCenter">
                        {(+data.price - (+data.price * +data.discount) / 100).toLocaleString()}
                        <span className="textSize9 textClr7">تومان</span>
                    </p>
                }
                </>
                :  
                <p className="faFont podcastFreeText textSize1 textClr3 flex alignCenter">
                رایگان
                </p>
            }
        </div>
    </div>
}
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../Style/profile.css";
import ProfileEditUser from "./ProfileEditUser";
import ProfileClasses from "./ProfileClasses";
export default function Profile(){
    const [isLoading,setIsLoading] = useState(false);
    const params = useParams();
    const [activeMenu ,setActiveMenu] = useState(params.id ? params.id : "home");
    const navigate = useNavigate();
    function changeMenuHandler(newMenu){
        setActiveMenu(newMenu);
        navigate(`/profile/${newMenu}`);
    }
    useEffect(() => {
        setActiveMenu(params.id ? params.id : "home");
    }, [navigate]);
    return <>
    {
        isLoading ? <></> :
        <section className="profileMainGrid grid container ">
            <div className="profileLinkList flex flexCol">
                <button onClick={()=>changeMenuHandler("editUserInfo")} className={`profileLinkItem faFont textSize9 textClr3 flex alignCenter ${activeMenu === "editUserInfo" ? "active" : ""}`}>
                    ویرایش اطلاعات
                </button>
                <button onClick={()=>changeMenuHandler("myClasses")} className={`profileLinkItem faFont textSize9 textClr3 flex alignCenter ${activeMenu === "myClasses" ? "active" : ""}`}>
                   کلاس های من
                </button>
            </div>
            <div className="flex flexCol profileDetailsDiv rel">
                {
                    activeMenu === "home" && <div className="profileHomeNavList flex flexCol">
                        <button className="profileHomeNavItem faFont textSize1 textClr3 flex alignCenter" onClick={()=>changeMenuHandler("editUserInfo")}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="20" width="25" xmlns="http://www.w3.org/2000/svg"><g id="Edit"><g><path d="M3.548,20.938h16.9a.5.5,0,0,0,0-1H3.548a.5.5,0,0,0,0,1Z"></path><path d="M9.71,17.18a2.587,2.587,0,0,0,1.12-.65l9.54-9.54a1.75,1.75,0,0,0,0-2.47l-.94-.93a1.788,1.788,0,0,0-2.47,0L7.42,13.12a2.473,2.473,0,0,0-.64,1.12L6.04,17a.737.737,0,0,0,.19.72.767.767,0,0,0,.53.22Zm.41-1.36a1.468,1.468,0,0,1-.67.39l-.97.26-1-1,.26-.97a1.521,1.521,0,0,1,.39-.67l.38-.37,1.99,1.99Zm1.09-1.08L9.22,12.75l6.73-6.73,1.99,1.99Zm8.45-8.45L18.65,7.3,16.66,5.31l1.01-1.02a.748.748,0,0,1,1.06,0l.93.94A.754.754,0,0,1,19.66,6.29Z"></path></g></g></svg>
                            ویرایش پروفایل
                        </button>
                        <button className="profileHomeNavItem faFont textSize1 textClr3 flex alignCenter" onClick={()=>changeMenuHandler("myClasses")}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="13" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38.33-62.14-49.94-112.62-112-112.62zm-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zM592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0z"></path></svg>
                            کلاس های من
                        </button>
                    </div>
                }
                {
                    activeMenu === "editUserInfo" && <ProfileEditUser />
                }
                {
                    activeMenu === "myClasses" && <ProfileClasses />
                }
            </div>
        </section>
    }
    </>
}
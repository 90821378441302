import React from "react";
import "../Style/homeLoading.css";

export default function HomeLoading(){
    return <>
        <div className="flex flexCol homeLoadingMainDiv">
            <div className="flex flexCol flexCenter mobileLoading">
                <div className="mobileSpin"></div>
            </div>
            <div className="flex flexCol desktopLoadingMainDiv">
                <div className="flex flexCol sliderLoading rel">
                    <div className="loaderItem"></div>
                </div>
                <div className="flex alignCenter storySection container">
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                </div>
                <div className="flex flexCol container">
                    <div className="flex flexCol middleBannerLoadingItem rel">
                        <div className="loaderItem"></div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
import React,{ useState, useEffect, useRef} from "react";
import "../Style/searchPage.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import NavsList from "./NavsList";
import FilterItem from "./FilterItem";
import PagePagintaion from "./PagePagination";
import { scrollToTop } from "./Methods";
export default function SearchPage(){
    const navigate = useNavigate();
    const [searchParam,] = useSearchParams();
    const [firstLoading ,setFirstLoading] = useState(true);
    const [data,setData] = useState({
        loading : false,
    });
    const pageNumber = useRef(1);
    const [showFilterList,setShowFilterList] = useState(false);
    const [activeCat,setActiveCat] = useState({id:"",fa:"همه نتایج"});
    const [filter,setFilter] = useState(searchParam.get("cat"));
    const [inputValue ,setInputValue] = useState(searchParam.get("q"));
    const winClick = useRef(false);
    const catsList = useRef([
        {fa:"دوره ها",id:"courses"},
        {fa:"ویدیو ها",id:"videos"},
        {fa:"ویدیوکست ها",id:"videoCasts"},
        {fa:"اساتید",id:"teeachers"},
        {fa:"مقالات",id:"blogs"},
        {fa:"پادکست ها",id:"podcasts"},
    ]);
    useEffect(() => {
        if(!winClick.current){
            winClick.current = true;
            window.addEventListener("click",(e)=>{
                let elem = e.target;
                if(elem.classList.contains("activeCatBtn") || 
                    elem.classList.contains("allCatsList") ||
                    elem.classList.contains("catsListItem") ||
                    elem.classList.contains("catItemLink") 
                ){
                    return;
                }
                setShowFilterList(false);
            });
        }
        if(!searchParam.get("q")){
            navigate("/");
        }
        setTimeout(() => {
            setFirstLoading(false);
            fetchData();
        }, 2000);
    }, []);
    function changePage(newPageNumber){
        setData({
            ...data,loading : true
        });
        searchParam.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function changeFilter(filterValue){
        setData({
            ...data,loading : true
        });
        searchParam.delete("p");
        if(filterValue){
            searchParam.set("cat",filterValue);
            window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        }else{
            searchParam.delete("cat");
            window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        }
        setFilter(filterValue);
        fetchData();
    }
    function fetchData(){
        // page number 
        // cat
        // q
        scrollToTop();
        setData({
            loading : false,
            resultList : [
                {
                    url : "/course/254",
                    title : "نصب یونیتی ۶",
                    cover : "/image/courseImg.webp",
                    type : "course",
                    price : 150000,
                    discount : 0
                },
                {
                    cover : "/image/videoCover.webp",
                    title : "آموزش تئاتر",
                    url : "/teacher/212",
                    type : "video",
                    price : 150000,
                    discount : 10
                },
                {
                    cover : "/image/videoCover.webp",
                    title : "آموزش فیلم نامه نویسی",
                    url : "/teacher/212",
                    type : "videoCast",
                    price : 150000,
                    discount : 0
                },
                {
                    url:"/podcast/1",
                    title : "پادکست شماره ۵",
                    cover : "/image/podcastImg.jpg",
                    type : "podcast",
                    price : 0,
                    discount : 0
                },
                {
                    title : "سعید راد",
                    url : "/teacher/1",
                    cover : "/image/teacherImg.webp",
                    expert : "متخصص داده",
                    type : "teacher"
                },
                {
                    title : "عنوان وبلاگ",
                    cover : "/image/blogCover.jpg",
                    url : "/blog/read_more_about_guitar",
                    type : "blogs"
                }
            ],
            totalPage : 10,
        })
        catsList.current.map((item)=>{
            if(item.id === searchParam.cat){
                setActiveCat(item);
            }
        })
    }
    function inputKeyUpHandler(e){
        if(e.key.toLowerCase() === "enter"){
            searchData();
        }
    }
    function searchData(){
        searchParam.set("q",inputValue);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        setData({
            ...data,loading : true
        });
        fetchData();
    }
    return <>
        {
            firstLoading ? <section className="flex flexCol container mar20">
                <div className="flex flexCol navLoading rel">
                    <div className="loaderItem"></div>
                </div>
                <div className="grid mainGrid">
                    <div className="grid courseFilterLoading">
                        <div className="searchInputLoading loadingStoryItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol filterLoadingBtn loadingStoryItem">
                            <div className="loaderItem"></div>
                        </div>
                    </div> 
                    <div className="grid allCastsLoading">
                        <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                    </div>
                </div>
            </section> : 
            <>
                <NavsList data={[{name:`نتایج جستجوی‌ : ${searchParam.get("q")}`,link:"/"}]} />
                <main className="grid mainGrid container searchMain">
                    <div className="grid mainGridRightDiv">
                        <div className="flex flexCol rightSideSearchDiv rel">
                            <input name="q" type="text" className="faFont textSize1 textClr3 courseSearchInput" autoComplete="off" placeholder="عبارت مورد نظر را اینجا وارد کنید" onKeyUp={(e)=>inputKeyUpHandler(e)} value={inputValue} onChange={(e)=>setInputValue(e.target.value)}/>
                            <button className="flex flexCenter courseSeachSubmitBtn" onClick={()=>searchData()}>
                                <svg className="searchIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
                            </button>
                        </div>
                        <div className="flex flexCol filterSection">
                            <button className="activeCatBtn faFont textSize11 textClr3 flex alignCenter justifyBetween" onClick={()=>setShowFilterList(!showFilterList)}>
                                <span className="activeCatText">
                                    { activeCat.fa }
                                </span>
                                <svg className={showFilterList ? "activeIcon arrowIcon" : "arrowIcon"} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                                <svg className="filterIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                            </button>
                            <div className={showFilterList ? "allCatsList active flex flexCol customScrollbar" : "allCatsList customScrollbar"} >
                                <FilterItem changeFilter={changeFilter} data={{url:"/" , name:filter , text: "همه نتایج" , id:undefined}} />
                                {
                                    catsList.current.map((item,index)=>{
                                        return <FilterItem changeFilter={changeFilter} data={{url:"/courses/"+ item.id , name:filter , text: item.fa , id:item.id}} key={index}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <section className="flex flexCol searchResultListSection">
                        <div className="searchResultGrid grid">
                            {
                                data.resultList.map((item,index)=>{
                                    return <div className="searchResultListItem grid rel" key={index}>
                                        <Link to={item.url} draggable="false" className="flex flexCol">
                                            <img src={item.cover} alt={item.title} className="searchResultImg" draggable="false" width={120} />
                                        </Link>
                                        <div className="flex flexCol searchResultInfoDiv">
                                            <Link className="faFont textSize12 textClr3 searchItemTitle" to={item.url} draggable="false">
                                            {
                                                item.title
                                            }
                                            </Link>
                                            <div className="flex alignCenter justifyBetween searchItemBottomDiv">
                                                <p className="faFont searchPrice textSize9 flex alignCenter">
                                                    {
                                                        item.type === "course" || item.type === "video" || item.type === "videoCast" || item.type === "podcast" ?
                                                        <>
                                                            {
                                                                item.price > 0 ? <>
                                                                {
                                                                    (+item.price - (+item.price * +item.discount) / 100).toLocaleString()
                                                                }
                                                                <span className="textClr7"> تومان</span>
                                                                </>
                                                                :
                                                                <span className="resultItemFree">رایگان</span>
                                                            }
                                                        </> :
                                                       <>
                                                        {
                                                            item.expert ?  <span className="textClr7">
                                                            {
                                                                item.expert
                                                            }
                                                            </span> :""
                                                        }
                                                       </>
                                                    } 
                                                </p> 
                                                <p className="faFont textSize9 textClr7 resultAt">
                                                    {
                                                        item.type === "course" ? "در صفحه دوره ها" :""
                                                    }
                                                    {
                                                        item.type === "video" ? "در صفحه ویدیو ها" :""
                                                    }
                                                    {
                                                        item.type === "videoCast" ? "در صفحه ویدیوکست ها" :""
                                                    }
                                                    {
                                                        item.type === "podcast" ? "در صفحه پادکست ها" :""
                                                    }
                                                    {
                                                        item.type === "teacher" ? "در صفحه اساتید" :""
                                                    }
                                                    {
                                                        item.type === "blogs" ? "در صفحه مقالات" :""
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            
                            {
                                data.loading && <div className="flex flexCol loadingMoreData container">
                                    <div className="flex flexCol flexCenter loadingMoreDataSubDiv">
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                                    </div>
                                </div>
                            }
                        </div>
                        <PagePagintaion data={{totalNumber:data.totalPage,
                                changePage : changePage, currentPage:pageNumber}} />
                    </section>
                </main>
            </>
        }
    </>
}
import React from "react";
export default function AlertMenu({data}){
    return <section className="flex flexCol alertMenuSection flexCenter" onClick={()=>data.setToggleAlert({id:"",show : false})}>
        <div className="flex flexCol alertMenuDiv" onClick={(e)=>e.stopPropagation()}>
            <div className="flex alertMenuHeaderDiv alignCenter justifyBetween">
                <p className="faFont textSize1 textClrF">
                   {
                    data.title
                   }
                </p>
                <button className="closeAlertMenuBtn flex flexCenter"  onClick={()=>data.setToggleAlert({id:"",show : false})}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
                    <span className="hideText">close</span>
                </button>
            </div>
            <div className="flex flexCol alertMenuContent">
                <p className="faFont textSize11 textClr3 justifyText">
                    {
                        data.content
                    }
                </p>
                <div className="flex alertMenuActionDiv">
                    <button className="cancelAlertBtn faFont textSize1"  onClick={()=>data.setToggleAlert({id:"",show : false})}>
                        انصراف
                    </button>
                    <button className="confirmBtn faFont textSize1" onClick={()=>data.confirm()}>
                        تایید
                    </button>
                </div>
            </div>
        </div>
    </section>
}
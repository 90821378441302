import React, { useEffect, useState } from "react";
import ProfileSubLoading from "./ProfileSubLoading";
import { Link } from "react-router-dom";
import {scrollToTop, showModal} from "./Methods";
import AlertMenu from "./AlertMenu";
export default function ProfileClasses(){
    const [firstLoading,setFirstLoading] = useState(true);
    const [data,setData] = useState({});
    const [progressLoading,setProgressLoading] = useState(false);
    useEffect(() => {
        scrollToTop();
        setTimeout(() => {
            setFirstLoading(false);
            setData([
                {
                    classId : "156546",
                    teahcerUrl : "/teacher/id",
                    teacherImg : "/image/teacherImg.webp",
                    teacherName : "کریمی",
                    courseName : "دوره آموزش پیانو",
                    day : "5",
                    dayName : "جمعه",
                    fullDate : "جمعه 5 مهر",
                    fullTime : "17:00 - 17:30"
                },
            ]);
        }, 1000);
    }, []);
    const [toggleAlert,setToggleAlert] = useState({id:"",show : false});
    function confirmCancelMenu(){
        setProgressLoading(true);
        setToggleAlert({...toggleAlert,show:false});
        setTimeout(() => {
            scrollToTop();
            setProgressLoading(false);
            setToggleAlert({id:"",show : false});
            showModal("کلاس با موفقیت لغو شد.");
        }, 1500);
    }
    return <div className="flex flexCol profileSubMainDiv">
    <Link to="/profile" className="faFont textSize1 textClr3 flex alignCenter backLink" draggable="false">
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg>
        بازگشت به پروفایل
    </Link>
    {
        firstLoading ? <ProfileSubLoading/> :
        <div className="flex flexCol profileClassList rel">
            {
                data.map((item,index)=>{
                    return <div className="grid profileClassItem" key={index}>
                        <p className="faFont textSize11 flex flexCol flexCenter classDate">
                            <span className="blue">{item.day}</span>
                            <span className="textClr7">
                                {item.dayName}
                            </span>
                        </p>
                        <div className="profileClassInfoDiv flex alignCenter">
                            <Link to={item.teahcerUrl} className="classItemTeacherLink flex flexCol" draggable="false">
                                <img src={item.teacherImg} alt={data.teacherName} className="classTeacherImg" draggable="false" width={60}/>
                            </Link>
                            <div className="flex flexCol teacherNameDiv">
                                <Link to={item.teahcerUrl} className="classItemTeacherName faFont textSize11 textClr3" draggable="false">
                                {
                                    item.teacherName
                                }
                                </Link>
                                <p className="faFont textSize9 textClr7 classItemCourseName">
                                {
                                    item.courseName
                                }
                                </p>
                            </div>
                            <button className="cancelClassItem flex flexCenter rel hoverText" onClick={()=>setToggleAlert({id:item.classId,show:true})}>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path></svg>
                                <span className="hideText">cancel</span>
                                <p className="faFont textSize8 textClr3 hoverMsg">
                                    لغو کلاس
                                </p>
                            </button>
                            <div className="classItemDateInfoDiv flex">
                                <p className="classItemDayText faFont textSize9 textClr3 flex alignCenter">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61 1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42A8.962 8.962 0 0 0 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9a8.994 8.994 0 0 0 7.03-14.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path></svg>
                                    {
                                        item.fullTime
                                    }
                                </p>
                                <p className="classItemDateText faFont textSize9 textClr3 flex alignCenter">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><path d="M6.445 12.688V7.354h-.633A13 13 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23"></path><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"></path><path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z"></path></svg>
                                    {
                                        item.fullDate
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                })
            }
            {
                progressLoading && <ProfileSubLoading/> 
            }
        </div>
    }
    {
        toggleAlert.show && <AlertMenu data={{title : "لغو کلاس" , toggleAlert:toggleAlert,setToggleAlert : setToggleAlert , confirm : confirmCancelMenu ,content : "برای لغو کلاس بر روی دکمه تایید کلیک کنید."}} />
    }
    </div>
}
import React, { useEffect, useState } from "react";
import ProfileSubLoading from "./ProfileSubLoading";
import { Link } from "react-router-dom";
import {scrollToTop, showModal} from "./Methods";
export default function ProfileEditUser(){
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState({
        username : "",
        firstName : "",
        lastName : "",
        birthDay : "",
        gender : "",
        resume : "",
        exproemce : ""
    });
    const [bDay , setBDay] = useState({y:1380,m:5,d:6});
    const [updateLoading,setUpdateLoading] = useState(false);
    const [updateError ,setUpdateError] = useState({error : false ,msg : ""});
    useEffect(() => {
        setTimeout(() => {
            scrollToTop()
            setLoading(false);
            setData({
                username : "09158771678",
                firstName : "امیرحسین",
                lastName : "دهقان",
                birthDay : "1371/14/04",
                gender : "m",
                resume : "CEO",
                experiences : "Phd"
            });
            setBDay({
                y:1380
                ,m:5
                ,d:6 
            })
        }, 1000);
    }, []);
    function formSubmitHandler(e){
        e.preventDefault();
        if(!data.username || !data.firstName || !data.lastName ){
            showModal("پر کردن فیلد های ستاره دار الزامی می باشد.")
            return;
        }
        setUpdateLoading(true);
        setUpdateError({error : false ,msg : ""});          
        setTimeout(() => {
            let result = {code:404,msg:"somethin wrong"};
            if(result.code === 200){
                setUpdateLoading(false);
                showModal("اطلاعات با موفقیت به روز شد.");
                scrollToTop();
            }else{
                setUpdateLoading(false);
                showModal(result.msg);
                setUpdateError({error : true ,msg : result.msg});          
            }
        }, 2000);
    }
    return <div className="flex flexCol profileSubMainDiv">
        <Link to="/profile" className="faFont textSize1 textClr3 flex alignCenter backLink" draggable="false">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg>
            بازگشت به پروفایل
        </Link>
        {
            loading ? <ProfileSubLoading/> :
            <form method="post" className="flex flexCol profileForm rel" onSubmit={(e)=>formSubmitHandler(e)}>
                <h1 className="faFont textSize12 textClr3">
                    ویرایش اطلاعات کاربری
                </h1>
                <fieldset className="formFieldSet flex flexCol gap5">
                    <label htmlFor="username" className="faFont textSize9 textClr7">
                        نام کاربری:
                    </label>
                    <input id="username" name="username" type="text" className="faFont textSize1 textClr3 customInput" disabled placeholder="نام کاربری" value={data.username}/>
                </fieldset>
                <fieldset className="formFieldSet flex flexCol gap5">
                    <label htmlFor="firstName" className="faFont textSize9 textClr7 flex alignCenter gap5">
                        <span className="red">*</span>
                        نام:
                    </label>
                    <input id="firstName" name="firstName" type="text" className="faFont textSize1 textClr3 customInput" placeholder="نام خود را وارد کنید." value={data.firstName} onChange={(e)=>setData({...data,firstName : e.target.value})} required/>
                </fieldset>
                <fieldset className="formFieldSet flex flexCol gap5">
                    <label htmlFor="lastName" className="faFont textSize9 textClr7 flex alignCenter gap5">
                        <span className="red">*</span>
                        نام خانوادگی:
                    </label>
                    <input id="lastName" name="lastName" type="text" className="faFont textSize1 textClr3 customInput" placeholder="نام خانوادگی خود را وارد کنید." value={data.lastName} onChange={(e)=>setData({...data,lastName:e.target.validationMessage})} required/>
                </fieldset>
                <fieldset className="formFieldSet flex flexCol gap5">
                    <label htmlFor="byear" className="faFont textSize9 textClr7">
                        تاریخ تولد:
                    </label>
                    <div className="birthdayGrid grid">
                        <input type="number" className="faFont textSize9 textClr3 customInput" min={1} max={31} maxLength={2} value={bDay.d} id="bday" onChange={(e)=>{if (e.target.value.length <= 2 && e.target.value <= 31) setBDay({...bDay,d:e.target.value}) }}/>
                        <input type="number" className="faFont textSize9 textClr3 customInput" min={1} max={12} maxLength={2} value={bDay.m} id="bmonth" onChange={(e)=>{if (e.target.value.length <= 2 && e.target.value <= 12) setBDay({...bDay,m:e.target.value}) }}/>
                        <input type="number" className="faFont textSize9 textClr3 customInput" min={1300} max={1450} maxLength={4} value={bDay.y} id="byear" onChange={(e)=>{if (e.target.value.length <= 4) setBDay({...bDay,y:e.target.value}) }}/>
                    </div>
                </fieldset>
                <fieldset className="formFieldSet flex flexCol gap5">
                    <p className="faFont textSize9 textClr5">جنسیت:</p>
                    <div className="flex alignCenter gap20">
                        <label htmlFor="m-gender" className="faFont textSize9 textClr7 flex alignCenter gap5">
                            <input type="radio" name="gender" id="m-gender" value="m" onChange={()=>setData({...data,gender:"m"})} checked={data.gender === "m"}/>
                            مرد
                        </label>
                        <label htmlFor="f-gender" className="faFont textSize9 textClr7 flex alignCenter gap5">
                            <input type="radio" name="gender" id="f-gender" value="f" onChange={()=>setData({...data,gender:"f"})} checked={data.gender === "f"}/>
                            زن
                        </label>
                    </div>
                </fieldset>
                <fieldset className="formFieldSet flex flexCol gap5">
                    <label htmlFor="resume" className="faFont textSize9 textClr7">
                        رزومه:
                    </label>
                    <textarea name="resume" id="resume" className="faFont textSize1 textClr3 customTextArea" placeholder="رزومه خود را وارد کنید." value={data.resume} onChange={(e)=>setData({...data,resume:e.target.validationMessage})}></textarea>
                </fieldset>
                <fieldset className="formFieldSet flex flexCol gap5">
                    <label htmlFor="experiences" className="faFont textSize9 textClr7">
                        تخصص:
                    </label>
                    <textarea name="experiences" id="experiences" className="faFont textSize1 textClr3 customTextArea" placeholder="تخصص خود را وارد کنید." value={data.experiences} onChange={(e)=>setData({...data,experiences:e.target.validationMessage})}></textarea>
                </fieldset>
                <button className="submitForm faFont textSize11 textClrF flex flexCenter">
                    ثبت
                </button>
                {
                    updateError.error &&
                    <p className="errorText faFont textSize9">
                        {
                            updateError.msg
                        }
                    </p>
                }
                {
                    updateLoading ? <ProfileSubLoading/> : ""
                }
            </form>
        }
    </div>
}
import React , { useRef , useEffect , useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "../Style/blogs.css";
import { scrollToTop } from "./Methods";
import NavsList from "./NavsList";
import BlogsSlider from "./BlogsSlider";
import BlogItem from "./BlogItem";
import PagePagintaion from "./PagePagination";


export default function Blogs(){
    const navigate = useNavigate();
    const [data,setData] = useState({
        loading : false,
    }); 
    const pageNumber = useRef(1);
    const [firstLoading,setFirstLoading] = useState(true);
    const [searchParam,] = useSearchParams();
    const blogsListGrid = useRef(null);
    const [showFilterList,setShowFilterList] = useState(false);
    const winClick = useRef(false);
    useEffect(()=>{
        document.title = "مقالات";
        scrollToTop();
        if(!winClick.current){
            winClick.current = true;
            window.addEventListener("click",(e)=>{
                let elem = e.target;
                if(elem.classList.contains("activeCatBtn") || 
                    elem.classList.contains("allCatsList") ||
                    elem.classList.contains("suggestList") ||
                    elem.classList.contains("suggestCatItem")
                ){
                    return;
                }
                setShowFilterList(false);
            });
        }
        // const data = new FormData();
        // data.append("username", userPhone);
        // data.append("token", userInfo.id);
        // data.append("code", `${signInputValues.n1}${signInputValues.n2}${signInputValues.n3}${signInputValues.n4}`);
        // fetch("https://web.bamamiresi.com/Enrollment/RegisterUser",{
        //     method:"POST",
        //     body:data,
        // })
        // .then((res) => {
        //     if (!res.ok) {
        //     throw new Error("102");
        //     }
        //     return res.json();
        // })
        // .then((json) => {
        //     if (json.error) {
        //         throw new Error(json.msg);
        //     }
        //     let result = json[0];
        //     if(result){
        //         setLoading(false);
        //         if(result.code == "500"){
        //             setSignError({result:true,msg:"کد وارد شده اشتباه است."});
        //             showModal("کد وارد شده اشتباه است.");
        //         }
        //     }
        // })
        // .catch((err) => {
        //     navigate("/404")
        // });
        fetchData();
    },[]);
    function inputKeyUpHandler(e){
        if(e.target.key === "enter"){
            searchData();
        }
    }
    function selectCatHandler(item){
        searchParam.delete("p");
        searchParam.delete("q");
        searchParam.set("cat",item.name);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function searchData(){
        setData({
            ...data,loading : true
        });
        pageNumber.current = 1;
        fetchData();
    }
    function fetchData(){
        pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
        setTimeout(() => {
            setData({
                loading : false,
                latestBlogs : [
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
                        url : "/blog/read_more_about_guitar",
                    },
                ],
                blogs:[
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05"
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05"
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05"
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05"
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05"
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05"
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "خلاصه مختصر وبلاگ",
                        url : "/blog/read_more_about_guitar",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05"
                    },
                    {
                        title : "عنوان وبلاگ",
                        cover : "/image/blogCover.jpg",
                        body : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
                        url : "/blog/read_more_about_guitar",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05"
                    },
                ],
                totalPage : 4, 
                suggestCat : [
                    {
                        name:"گیتار",
                        cat : "guitar"
                    },
                    {
                        name:"پیانو",
                        cat : "guitar"
                    },
                    {
                        name:"تئاتر",
                        cat : "guitar"
                    },
                    {
                        name:"سفالگری",
                        cat : "guitar"
                    },
                ]
            });
            setFirstLoading(false);
        }, 2000);
    }
    function changePage(newPageNumber){
        if(!blogsListGrid.current){
            blogsListGrid.current = document.getElementById("blogsListGrid");
        }
        blogsListGrid.current.scrollIntoView();
        setData({
            ...data,loading : true
        });
        searchParam.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    return <>
    {
        firstLoading ? <section className="flex flexCol container blogsLoadingSection">
            
            <div className="flex flexCol loadingLatestBlogs rel">
                <div className="loaderItem"></div>
            </div>
            <div className="grid loadingBlogsMiddleGrid">
                <div className="flex flexCol loadingBlogSearchDiv rel">
                    <div className="loaderItem"></div>
                </div>
                <div className="grid loadingBlogResultList">
                    <div className="flex flexCol loadingBlogItem rel">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol loadingBlogItem rel">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol loadingBlogItem rel">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol loadingBlogItem rel">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol loadingBlogItem rel">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol loadingBlogItem rel">
                        <div className="loaderItem"></div>
                    </div>
                </div>
            </div>
        </section> : <>
            <NavsList data={[{name:"مقالات",link:"/blogs"}]} />
            <BlogsSlider data={data.latestBlogs} />
            <section className="grid blogsListGrid container" id="blogsListGrid">
                <div className="grid mainGridRightDiv">
                    <div className="flex flexCol rightSideSearchDiv rel">
                        <input name="q" type="text" className="faFont textSize1 textClr3 courseSearchInput" autoComplete="off" placeholder="جستجو در مقالات" onKeyUp={(e)=>inputKeyUpHandler(e)}/>
                        <button className="flex flexCenter courseSeachSubmitBtn" onClick={()=>searchData}>
                            <svg className="searchIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
                        </button>
                    </div>
                    <div className="flex flexCol filterSection">
                        <button className="activeCatBtn faFont textSize11 textClr3 flex alignCenter justifyBetween" onClick={()=>setShowFilterList(!showFilterList)}>
                            <span className="hideText">
                                categories
                            </span>
                            <svg className={showFilterList ? "activeIcon arrowIcon" : "arrowIcon"} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            <svg className="filterIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                        </button>
                        <div className={showFilterList ? "allCatsList active flex flexCol customScrollbar" : "allCatsList customScrollbar"} >
                            <h4 className="faFont suggestCatTitle textSize1 textClr5">
                                دسته بندی های پربازدید :
                            </h4>
                            <div className="flex suggestList">
                                {
                                    data.suggestCat.map((item,index)=>{
                                        return <button className="faFont textSize9 textClr3 flex alignCenter suggestCatItem" key={index} onClick={()=>selectCatHandler(item)}>
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"></path></svg>
                                            {
                                                item.name
                                            }
                                        </button>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flexCol rel">
                    <div className="grid blogResultList rel">
                        {
                            data.blogs.map((item,index)=>{
                                return <BlogItem data={item} key={index}/>
                            })
                        }
                        {
                            data.loading && <div className="flex flexCol loadingMoreData container">
                                <div className="flex flexCol flexCenter loadingMoreDataSubDiv">
                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                                </div>
                            </div>
                        }
                    </div>
                    <PagePagintaion data={{totalNumber:data.totalPage,
                            changePage : changePage, currentPage:pageNumber}} />
                </div>
            </section>
        </>
    }
    </>
}
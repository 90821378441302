import React from "react";
import { Link } from "react-router-dom";
import "../Style/podcastGrid.css";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import PodcastItem from "./PodcastItem";

export default function PodcastGrid({data,part}){
    return <>
        <section className="flex flexCol container podcastSection">
            <div className="flex alignCenter justifyBetween sectionHeader">
                <h2 className="faFont textSize12 textClr3 rel sectionTitle flex alignCenter gap5">
                    { part.titleLogo }
                    { part.titleText }
                </h2>
                <Link to="/podcasts" className="faFont textSize1 textClr3 sectionLink flex alignCenter gap5">
                    { part.linkText }
                    { part.linkLogo }
                </Link>
            </div>
            <Swiper className="swiper podcastSwiper rel"
                spaceBetween={15}
                slidesPerView={2}
                breakpoints={{
                    649:{
                        slidesPerView: 3,
                        spaceBetween : 20
                    },
                    868:{
                        slidesPerView: 4,
                        spaceBetween : 20
                    },
                    1000:{
                        slidesPerView: 5,
                        spaceBetween : 10
                    },
                    1200:{
                        slidesPerView: 6,
                        spaceBetween : 10
                    },
                }}
            >
                {
                    data.map((item,index)=>{
                        return <SwiperSlide key={index}>
                            <PodcastItem data={item}/>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    </>
}
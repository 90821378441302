import React, { useEffect, useState } from "react";
import "../Style/cart.css";
import NavsList from "./NavsList";
import { Link } from "react-router-dom";
import { cutText, scrollToTop } from "./Methods";
export default function Cart(){
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState(null);
    useEffect(() => {
        scrollToTop();
        setTimeout(() => {
            setLoading(false);
            setData(
                {
                    cartItems : [
                        {
                            title : "دوره اموزش پیانو",
                            cover : "/image/courseImg.webp",
                            link : "/course/courseId",
                            discount : "10",
                            price : "250000",
                            id : "5"
                        },
                        {
                            title : "دوره اموزش پیانو",
                            cover : "/image/courseImg.webp",
                            link : "/course/courseId",
                            discount : "0",
                            price : "250000",
                            id : "6"
                        },
                    ],
                    totalPrice : 250000,
                    discount : 10
                }
            );
        }, 3000);
    }, []);
    return <>
        {
            loading ? <section className="cartPageLoadingSection grid container">
                <div className="cartPageDetailsLoading flex flexCol rel">
                    <div className="loaderItem"></div>
                </div>
                <div className="cartPageSummaryLoading flex flexCol rel">
                    <div className="loaderItem"></div>
                </div>
            </section> : <>
                <NavsList data={[{name:"سبد خرید",link:"/cart"}]} />
                {
                    data.cartItems.length > 0 ? <section className="cartPageMainSection grid container">
                        <div className="flex flexCol cartPageRightDiv">
                            <h1 className="faFont textSize14 textClr3 cartPageTitle">
                                جزئیات سبد خرید
                            </h1>
                            <div className="flex flexCol cartPageDetailsList">
                                {
                                    data.cartItems && data.cartItems.map((item,index)=>{
                                        return <div className="cartDetailsItem grid" key={index}>
                                            <Link to={item.link} className="flex flexCol" draggable="false">
                                                <img src={item.cover} alt={item.title} className="cartItemImg" draggable="false" width={150} height={100}/>
                                            </Link>
                                            <div className="flex flexCol cartItemInfoDiv">
                                                <Link to={item.link} className="faFont textClr3 cartItemInfoLink" draggable="false">
                                                    {
                                                        item.title
                                                    }
                                                </Link>
                                                {
                                                    +item.discount > 0 ? <div className={"flex alignCenter justifyBetween cartItemDiscountDiv"}>
                                                        <p className="cartItemDiscountPercent faFont flex alignCenter justifyBetween">
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M109.25 173.25c24.99-24.99 24.99-65.52 0-90.51-24.99-24.99-65.52-24.99-90.51 0-24.99 24.99-24.99 65.52 0 90.51 25 25 65.52 25 90.51 0zm256 165.49c-24.99-24.99-65.52-24.99-90.51 0-24.99 24.99-24.99 65.52 0 90.51 24.99 24.99 65.52 24.99 90.51 0 25-24.99 25-65.51 0-90.51zm-1.94-231.43l-22.62-22.62c-12.5-12.5-32.76-12.5-45.25 0L20.69 359.44c-12.5 12.5-12.5 32.76 0 45.25l22.62 22.62c12.5 12.5 32.76 12.5 45.25 0l274.75-274.75c12.5-12.49 12.5-32.75 0-45.25z"></path></svg>
                                                            {
                                                                item.discount
                                                            }
                                                        </p>
                                                        <p className="cartItemBeforeDiscountPrice faFont textClr7">
                                                            {
                                                                (+item.price).toLocaleString()
                                                            }
                                                        </p>
                                                    </div> : ""
                                                }
                                                <p className="cartItemFinalPrice faFont textClr3">
                                                    {
                                                        (+item.price - (+item.price * +item.discount) / 100).toLocaleString()
                                                    }
                                                    تومان
                                                </p>
                                            </div>
                                            <button className="cartItemRemoveBtn faFont textClrF flex flexCenter">
                                                حذف
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                            </button>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="flex flexCol cartPageLeftDiv">
                            <div className="summaryItemDiv flex alignCenter justifyBetween">
                                <p className="faFont textSize1 textClr7 summaryTitle">
                                    قیمت :
                                </p>
                                <p className="faFont textSize1 textClr3">
                                    {
                                        (+data.totalPrice).toLocaleString()
                                    } تومان
                                </p>
                            </div>
                            <div className="summaryItemDiv flex alignCenter justifyBetween">
                                <p className="faFont textSize1 textClr7 summaryTitle">
                                    تخفیف :
                                </p>
                                <p className="faFont textSize1 textClr3">
                                {
                                    ((+data.totalPrice * +data.discount)/ 100).toLocaleString()
                                } تومان
                                </p>
                            </div>
                            <div className="summaryItemDiv flex alignCenter justifyBetween">
                                <p className="faFont textSize1 textClr7 summaryTitle">
                                    مبلغ نهایی :
                                </p>
                                <p className="faFont textSize1 textClr3">
                                    {
                                        (+data.totalPrice - (+data.totalPrice * +data.discount)/ 100).toLocaleString()

                                    }  تومان 
                                </p>
                            </div>
                            <Link to="/submitCart" className="submitCartLink faFont textSize11 textClrF flex flexCenter">
                                تایید و ادامه
                            </Link>
                        </div>
                    </section>
                    :
                    <>
                        <section className="flex flexCol flexCenter emptyCartSection container">
                            <svg className="emptyCartIcon" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M232,72l-25.63,92.28A16,16,0,0,1,191,176H92.16a16,16,0,0,1-15.41-11.72L51.11,72Z" opacity="0.2"></path><path d="M104,216a16,16,0,1,1-16-16A16,16,0,0,1,104,216Zm88-16a16,16,0,1,0,16,16A16,16,0,0,0,192,200ZM239.71,74.14l-25.64,92.28A24.06,24.06,0,0,1,191,184H92.16A24.06,24.06,0,0,1,69,166.42L33.92,40H16a8,8,0,0,1,0-16H40a8,8,0,0,1,7.71,5.86L57.19,64H232a8,8,0,0,1,7.71,10.14ZM221.47,80H61.64l22.81,82.14A8,8,0,0,0,92.16,168H191a8,8,0,0,0,7.71-5.86Z"></path></svg>
                            <h2 className="faFont textSize14 textClr7 emptyCartTitle">
                                سبد خرید شما خالی است.
                            </h2>
                        </section>
                    </>
                }
            </>
        }
    </>
}
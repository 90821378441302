import React, { useEffect, useRef, useState }  from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { scrollToTop } from "./Methods";
import "../Style/allPodcasts.css";
import NavsList from "./NavsList";
import FilterItem from "./FilterItem";
import PagePagintaion from "./PagePagination";
import FullBanner from "./FullBanner";
import PodcastItem from "./PodcastItem";

export default function AllPodcasts(){
    const [data,setData] = useState({
        resultList : null,
        catsList : null,
        middleBanner : null,
        totalPage : 0,
    });
    const [isLoading,setIsLoading] = useState({
        filter : true,
        data : false
    });
    const params = useParams();
    const pageNumber = useRef(1);
    const [showFilterList,setShowFilterList] = useState(false);
    const [activeCat,setActiveCat] = useState({id:"",fa:"همه دوره ها"});
    const [searchParam,] = useSearchParams();
    const [filter,setFilter] = useState(searchParam.get("q"));
    useEffect(() => {
        setData({
            ...data,list : null
        });
        setIsLoading({
            ...isLoading,data : true
        });
        fetchData();
    }, []);
    function fetchData(){
        pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
        scrollToTop();
        let catsList = [
            {fa:"آموزش گیتار",id:"guitar"},
            {fa:"آموزش پیانو",id:"piano"},
            {fa:"آموزش تئاتر",id:"teater"},
            {fa:"آموزش ویلون",id:"violon"},
        ];
        setTimeout(() => {
            setData({
                middleBanner : {
                    url : "",
                    cover: "/image/middleBanner.webp",
                    mobileCover: "/image/mobileMiddleBanner.webp",
                    title : "something" 
                },
                catsList : catsList,
                resultList : [
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 50,
                        price: 100000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 0,
                        price: 0
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 10,
                        price: 200000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 0,
                        price: 200000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 10,
                        price: 200000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 10,
                        price: 200000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 50,
                        price: 100000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 0,
                        price: 0
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 10,
                        price: 200000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 0,
                        price: 200000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 10,
                        price: 200000
                    },
                    {
                        url:"/podcast/1",
                        title : "پادکست شماره ۵",
                        cover : "/image/podcastImg.jpg",
                        duration : "۱۰:۰۰",
                        src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                        discount : 10,
                        price: 200000
                    },
                ],
                totalPage : 10,
            });
            catsList.map((item)=>{
                if(item.id === params.query){
                    setActiveCat(item);
                }
            })
            setIsLoading({
                filter : false,
                data : false
            });
        }, 2000);
    }
    function inputKeyUpHandler(e){
        if(e.target.key === "enter"){
            searchData();
        }
    }
    function searchData(){
        setData({
            ...data,list : null
        });
        setIsLoading({
            ...isLoading,data : true
        });
        fetchData();
    }
    function changePage(newPageNumber){
        setIsLoading({
            ...isLoading,data : true
        });
        searchParam.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function changeFilter(filterValue){
        setIsLoading({
            ...isLoading,data : true
        });
        searchParam.delete("p");
        if(filterValue){
            searchParam.set("q",filterValue);
            window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        }else{
            searchParam.delete("q");
            window.history.pushState({},"",`${window.location.pathname}`);
        }
        setFilter(filterValue);
        fetchData();
    }
    return <>
        <NavsList data={[{name:"پادکست ها",link:"/podcasts"}]} />
        <main className="grid mainGrid container" onClick={()=>{showFilterList && setShowFilterList(false)}}>
            {
                isLoading.filter ? <div className="grid courseFilterLoading">
                    <div className="searchInputLoading loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol filterLoadingBtn loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                </div> :
                <div className="grid mainGridRightDiv">
                    <div className="flex flexCol rightSideSearchDiv rel">
                        <input name="q" type="text" className="faFont textSize1 textClr3 courseSearchInput" autoComplete="off" placeholder="جستجوی نام استاد یا پادکست" onKeyUp={(e)=>inputKeyUpHandler(e)}/>
                        <button className="flex flexCenter courseSeachSubmitBtn" onClick={()=>searchData}>
                            <svg className="searchIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
                        </button>
                    </div>
                    <div className="flex flexCol filterSection">
                        <button className="activeCatBtn faFont textSize11 textClr3 flex alignCenter justifyBetween" onClick={()=>setShowFilterList(!showFilterList)}>
                            <span className="activeCatText">
                                { activeCat.fa }
                            </span>
                            <svg className={showFilterList ? "activeIcon arrowIcon" : "arrowIcon"} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            <svg className="filterIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                        </button>
                        <div className={showFilterList ? "allCatsList active flex flexCol customScrollbar" : "allCatsList customScrollbar"} >
                            <FilterItem changeFilter={changeFilter} data={{url:"/courses" , name:filter , text: "همه دوره ها" , id:undefined}} />
                            {
                                data.catsList.map((item,index)=>{
                                    return <FilterItem changeFilter={changeFilter} data={{url:"/courses/"+ item.id , name:filter , text: item.fa , id:item.id}} key={index}/>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                data.resultList ? <div className="flex flexCol rel courseSectionLeftDiv">
                    <div className="podcastGrid grid">
                        {
                            data.resultList.map((item,index)=>{
                                return <PodcastItem data={item} key={index}/>
                            })
                        }
                    </div>
                    <PagePagintaion data={{totalNumber:data.totalPage,
                        changePage : changePage, currentPage:pageNumber}} />
                    {
                        isLoading.data && <div className="flex flexCol loadingMoreData container">
                            <div className="flex flexCol flexCenter loadingMoreDataSubDiv">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                            </div>
                        </div>
                    }
                </div>
                : <div className="grid allCastsLoading">
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                </div>
            }
        </main>
        {data.middleBanner && <FullBanner data={data.middleBanner} />}
    </>
}
import "./App.css";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { createContext, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Component/Header";
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import CoursesPage from "./Component/CoursesPage";
import Login from "./Component/Login";
import Player from "./Component/Player";
import VideoPage from "./Component/VideosPage";
import TeachersPage from "./Component/TeachersPage";
import TeacherDetails from "./Component/TeacherDetails";
import BlogPage from "./Component/BlogPage";
import FivePage from "./Component/FivePage";
import PodcastDetailsPage from "./Component/PodcastDetailsPage";
import Modal from "./Component/Modal";
import AllPodcasts from "./Component/AllPodcasts";
import CourseDetailsPage from "./Component/CourseDetailsPage";
import ReservePage from "./Component/ReservePage";
import SchoolPage from "./Component/SchoolPage";
import VideoDetailsPage from "./Component/VideoDetailsPage";
import VideoCastDetailsPage from "./Component/VideoCastDetailsPage";
import { createId } from "./Component/Methods";
import Blogs from "./Component/Blogs";
import BlogDetails from "./Component/BlogDetails";
import About from "./Component/About";
import SearchPage from "./Component/SearchPage";
import Contact from "./Component/Contact";
import Cart from "./Component/Cart";
import Profile from "./Component/Profile";
import AllSchools from "./Component/AllSchools";
import SearchSchool from "./Component/SearchSchool";
export const AppContext = createContext();
function App() {
  const uid = useRef(Cookies.get("uid"));
  if (!uid.current) {
    uid.current = createId();
    if (!Cookies.get("uid")) {
      uid.current = CryptoJS.SHA1(uid.current).toString();
      Cookies.set("uid", uid.current);
    }
  }
  const [userInfo, setUserInfo] = useState({
    id: uid.current,
    data: undefined,
  });
  const [player, setPlayer] = useState(null);
  return (
    <>
      <AppContext.Provider
        value={{
          userData: [userInfo, setUserInfo],
          playerInfo: [player, setPlayer],
        }}
      >
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index path="/" element={<Home />}></Route>
            <Route path="/courses" element={<CoursesPage />}></Route>
            <Route
              path="/videos"
              element={<VideoPage pageName={"videos"} />}
            ></Route>
            <Route
              path="/casts"
              element={<VideoPage pageName={"casts"} />}
            ></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/teachers" element={<TeachersPage />}></Route>
            <Route path="/teacher/:id" element={<TeacherDetails />}></Route>
            <Route path="/blog" element={<BlogPage />}></Route>
            <Route path="/podcasts" element={<AllPodcasts />}></Route>
            <Route path="/podcast/:id" element={<PodcastDetailsPage />}></Route>
            <Route path="/course/:id" element={<CourseDetailsPage />}></Route>
            <Route path="/505" element={<FivePage />}></Route>
            <Route path="/reserve/:id/:name" element={<ReservePage />}></Route>
            <Route path="/school/:id/" element={<SchoolPage />}></Route>
            <Route path="/video/:id/" element={<VideoDetailsPage />}></Route>
            <Route path="/cast/:id/" element={<VideoCastDetailsPage />}></Route>
            <Route path="/blogs" element={<Blogs />}></Route>
            <Route path="/blog/:name/" element={<BlogDetails />}></Route>
            <Route path="/aboutus" element={<About />}></Route>
            <Route path="/search" element={<SearchPage />}></Route>
            <Route path="/contactus" element={<Contact />}></Route>
            <Route path="/cart" element={<Cart />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
            <Route path="/profile/:id" element={<Profile />}></Route>
            <Route path="/schools" element={<SearchSchool />}></Route>
          </Routes>
          <Modal />
          <Player />
          <Footer />
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
}
export default App;

import React from "react";
import {Swiper , SwiperSlide} from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import SwiperNavBtns from "./SwiperNavBtn";
import "../Style/blogs.css";
import { Link } from "react-router-dom";
import { cutText } from "./Methods";

export default function BlogsSlider({data}){
    let hasNav = false;
    if(data && data.length > 1){
        hasNav = {
            prevEl: '.goToPreLargeSlide',
            nextEl: '.goToNextLargeSlide',
        }
    }
    return <section className="flex flexCol blogsSliderSection">
        <h1 className="faFont textSize14 textClr3 blogsPageTitle container">
            آخرین مقالات
        </h1>
        <Swiper className="swiper blogSlider rel" 
                modules={[Autoplay,Navigation,Pagination]}
                slidesPerView={1.15}
                autoplay={{
                    delay: 5000,
                }}
                spaceBetween={0}
                navigation={hasNav}
                centeredSlides={true}
                pagination={{clickable:true}}
                breakpoints={{
                    768:{
                        slidesPerView: 1,
                        centeredSlides: false
                    },
                    1100:{
                        slidesPerView:2,
                        centeredSlides:true,
                    }
                }}
            >
            {
                data.map((item,index)=>{
                    return <SwiperSlide key={index}>
                    <div className="grid blogSliderItem rel" >
                        <Link to={item.url} className="flex flexCol blogSliderImgLink" draggable="false">
                            <img src={item.cover} alt={item.title} className="blogSliderImg" draggable="false" width="100%"/>
                        </Link>
                        <div className="flex flexCol blogSliderInfo">
                            <Link to={item.url} className="faFont textSize12 textClr3 blogSliderTitle">
                            {
                                item.title
                            }
                            </Link>
                            <p className="faFont textSize1 textClr5 blogDesc">
                                {
                                    cutText(item.body , 152)
                                }
                            </p>
                            <Link to={item.url} className="faFont textSize1 sliderBlogSeeLink flex alignCenter">
                                مشاهده مقاله
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                })
            }
            {
                hasNav && <SwiperNavBtns />
            }
        </Swiper>
    </section>
}
import React from "react";
import "../Style/largeSlider.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper/modules";
import 'swiper/css';
import { Link } from "react-router-dom";
import SwiperNavBtns from "./SwiperNavBtn";

export default function LargeSlider(props){
    const data = props.data;
    let hasNav = false;
    let preLink = "https://web.bamamiresi.com/";
    if(data.slider && data.slider.length > 1){
        hasNav = {
            prevEl: '.goToPreLargeSlide',
            nextEl: '.goToNextLargeSlide',
        }
    }
    return <>
        {
            <Swiper className="swiper sliderSwiper rel" 
                modules={[Autoplay,Navigation]}
                slidesPerView={1}
                loop={data.slider.length > 1}
                autoplay={{
                    delay: 5000,
                }}
                navigation={hasNav}
                >
                {
                    data.slider.map((item,index)=>{
                        return <SwiperSlide key={index}>
                        <div className="flex flexCol silderItem rel" >
                            <Link to={item.link} className="sliderImageLink flex flexCol">
                                <img src={item.mobile} alt={item.name} className="mobileSliderImg" />
                                <img src={item.tablet} alt={item.name} className="tabletSliderImg" />
                                <img src={item.desktop} alt={item.name} className="desktopnSliderImg" />
                            </Link>
                        </div>
                    </SwiperSlide>
                    })
                }
                {
                    hasNav && <SwiperNavBtns />
                }
            </Swiper>
        }
    </>
}
import React, { useEffect, useRef, useState } from "react";
import NavsList from "./NavsList";
import "../Style/blogPage.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper/modules";
import 'swiper/css';
import { Link, useParams, useSearchParams } from "react-router-dom";
import { scrollToTop } from "./Methods";

export default function BlogPage(){
    const [data,setData] = useState({
        recentBlog : null,
    })
    const [isLoading,setIsLoading] = useState({
        data : false
    });
    const params = useParams();
    const pageNumber = useRef(1);
    const [searchParam,] = useSearchParams();
        
    useEffect(() => {
        setIsLoading({
            ...isLoading,data : true
        });
        fetchData();
    }, []);
    function fetchData(){
        pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
        scrollToTop();
        setTimeout(() => {
            setData({
                totalPage:100,
                recentBlog : [
                    {
                        title : "چطور آیفونی را که خاموش نمی‌شود درست کنیم؟",
                        cover : "/image/blogCover.jpg",
                        body : "گاهی اوقات هنگامی که برای خاموش کردن آیفون خود اقدام می‌کنید، می‌بینید گوشی به روش معمول خاموش نمی‌شود. دلیل این موضوع چه نرم‌افزاری و چه سخت‌افزاری باشد، خوشبختانه رفع ایراد آیفونی که خاموش نمی‌شود،‌ اغلب ساده و سرراست است. در این مقاله چند روش برای رفع مشکل خاموش نشدن گوشی آیفون ارائه کرده‌ایم.",
                        url : "blogId",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05",
                        author : "محمد راد",
                        authorPic : "/image/teacherImg.webp",
                        authorId : "10"
                    },
                    {
                        title : "فورس ریستارت (Force Restart) آیفون",
                        cover : "/image/blogCover.jpg",
                        body : "اگر نمی‌توانید از طریق تنظیمات یا کنترل‌سنتر آیفون خود را خاموش کنید، ممکن است فورس ریستارت گوشی مشکل را حل کند. فورس ریستارت آیفون اغلب نقص‌های موقتی را که مانع خاموش شدن عادی گوشی می‌شوند، برطرف می‌کند. روش این کار به مدل آیفون شما بستگی دارد:",
                        url : "blogId",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05",
                        author : "محمد راد",
                        authorPic : "/image/teacherImg.webp",
                        authorId : "10"
                    },
                    {
                        title : "بازیابی از نسخه‌ی پشتیبان",
                        cover : "/image/blogCover.jpg",
                        body : "اگر آیفون شما هنوز هم به دستورات پاسخی نمی‌دهد و خاموش نمی‌شود، شاید بازیابی یک نسخه‌ی پشتیبان مشکلات عمیق‌تر نرم‌افزاری را حل کند. اگر نسخه‌ی پشتیبان دارید",
                        url : "blogId",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05",
                        author : "محمد راد",
                        authorPic : "/image/teacherImg.webp",
                        authorId : "10"
                    },
                    {
                        title : "لرزش مدار مریخ ممکن است نشانه پرواز سیاه‌چاله‌ای آغازین در منظومه شمسی باشد",
                        cover : "/image/blogCover.jpg",
                        body : "فیزیکدانان در مطالعه‌ای جدید بیان می‌کنند که اگر بخش عمده‌ی ماده تاریک جهان از سیاه‌چاله‌های آغازین میکروسکوپی تشکیل شده باشد، پس این کوتوله‌های گرانشی باید دست‌کم یک مرتبه در هر دهه به منظومه شمسی سفر کنند. پژوهشگران پیش‌بینی می‌کنند که پرواز سریع این سیاه‌چاله‌ها، مدار مریخ را تا حدی که با فناوری امروزی آشکارشدنی است، دچار تغییرات جزئی خواهد کرد.",
                        url : "blogId",
                        visit : 5,
                        readTime : 5,
                        date : "1402/04/05",
                        author : "محمد راد",
                        authorPic : "/image/teacherImg.webp",
                        authorId : "10"
                    },
                ]
            });
            setIsLoading({
                data : false
            });
        }, 5000);
    }
    return <>
        <NavsList data={[{name:"مقالات",link:"/videos"}]} />
        {
            data.recentBlog ? <section className="flex flexCol rel recentBlogsContainer container">
                <Swiper className="swiper recentSwiper rel" 
                    modules={[Autoplay,Pagination]}
                    slidesPerView={1}
                    spaceBetween={20}
                    loop={data.recentBlog.length > 1}
                    autoplay={{
                        delay: 5000,
                    }}
                    pagination={{
                        clickable: true
                    }}
                >
                    {
                        data.recentBlog.map((item,index)=>{
                            return <SwiperSlide key={index}>
                            <div className="grid recentBlogItem" key={index}>
                                <Link to={"/blog/"+item.url} className="recentBlogItemImgLink flex flexCol">
                                    <img src={item.cover} alt={item.title} className="recentBlogImg" />
                                </Link>
                                <div className="flex flexCol recentBlogInfoDiv">
                                    <div className="flex alignCenter gap5 authorDiv">
                                        <Link to={"/teacher/"+item.authorId} className="flex flexCol authorImgLink">
                                            <img src={item.authorPic} alt={item.author} className="authorImg" draggable="false"/>
                                        </Link>
                                        <Link to={"/teacher/"+item.authorId} className="faFont textSize1 textClr3 authorName flex flexCol">
                                            <span className="textSize8">
                                                نویسنده :
                                            </span>
                                            {
                                                item.author
                                            }
                                        </Link>
                                    </div>
                                    <Link to={"/blog/"+item.url} className="faFont textClr3 recentBlogTitle">
                                        {
                                            item.title
                                        }
                                    </Link>
                                    <p className="faFont textClr5 justifyText recentBlogDesc">
                                        {
                                            item.body
                                        }
                                    </p>
                                    <div className="flex recentBlogFooter">
                                        <p className="faFont textClr5 recentBlogDate flex alignCenter">
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6.445 12.688V7.354h-.633A13 13 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23"></path><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"></path><path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z"></path></svg>
                                            ۱۳۹۹/۱۰/۲۲
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        })
                    }
                </Swiper>
            </section> : 
            "Loading blog"
        }
    </>
}
import React ,{useEffect, useState} from "react";
import "../Style/contact.css";
import { scrollToTop,showModal } from "./Methods";
export default function Contact(){
    const [inputs,setInputs] = useState({
        category : "انتقادات و پیشنهادات",
        fullname : "",
        phone : "",
        message : "",
        loading : false
    });
    useEffect(() => {
        scrollToTop();
    }, []);
    function submitHandler(e){
        e.preventDefault();
        if(!inputs.category || 
            !inputs.fullname || 
            !inputs.phone || 
            !inputs.message 
        ){
            showModal("لطفا ابتدا تمامی کادرها را پر کنید.");
            return;
        }
        scrollToTop();
        setInputs({...inputs,loading:true});
        setTimeout(() => {
            showModal("پیغام شما با موفقیت ثبت شد.");
            setInputs({...inputs,
                fullname : "",
                phone : "",
                message : ""
            });
            scrollToTop();
        }, 2000);
    }
    return <section className="contactGrid grid container">
        <form method="post" onSubmit={(e)=>submitHandler(e)} className="flex flexCol contactForm rel">
            <h1 className="faFont textSize16 textClr3 formTitle">
                تماس با ما
            </h1>
            <fieldset className="flex flexCol">
                <label htmlFor="category" className="faFont textSize9 textClr5 formLabel">
                    انتخاب موضوع :
                </label>
                <select name="category" id="category" className="faFont textSize1 textClr3 categorySelect" onChange={(e)=>setInputs({...inputs,category:e.target.value})}>
                    <option value="انتقادات" className="faFont textSize1 textClr3 categoryOption">
                        انتقادات و پیشنهادات
                    </option>
                    <option value="مدیریت" className="faFont textSize1 textClr3 categoryOption">
                        مدیریت
                    </option>
                    <option value="شکایات" className="faFont textSize1 textClr3 categoryOption">
                        شکایات
                    </option>
                    <option value="تبلیغات" className="faFont textSize1 textClr3 categoryOption">
                        تبلیغات
                    </option>
                    <option value="سایر" className="faFont textSize1 textClr3 categoryOption">
                        سایر
                    </option>
                </select>
            </fieldset>
            <fieldset className="flex flexCol rel">
                <label htmlFor="userFullName" className="faFont textSize9 textClr5 formLabel">
                    نام و نام خانوادگی :
                </label>
                <input type="text" name="userFullName" id="userFullName" className="faFont contactCustomInput textSize1 textClr3" placeholder="نام و نام خانوادگی خود را اینجا وارد کنید"  value={inputs.fullname} onChange={(e)=>setInputs({...inputs,fullname:e.target.value})} required/>
            </fieldset>
            <fieldset className="flex flexCol">
                <label htmlFor="userPhone" className="faFont textSize9 textClr5 formLabel">
                    شماره تماس
                </label>
                <input type="tel" name="userPhone" id="userPhone" className="faFont contactCustomInput textSize1 textClr3" placeholder="شماره تماس خود را اینجا وارد کنید"  value={inputs.phone} onChange={(e)=>setInputs({...inputs,phone:e.target.value})} required/>
            </fieldset>
            <fieldset className="flex flexCol">
                <label htmlFor="userMessage" className="faFont textSize9 textClr5 formLabel">
                    پیام
                </label>
                <textarea name="userMessage" id="userMessage" className="faFont textSize1 textClr3 contactCustomTextArea" placeholder="پیام خود را اینجا وارد کنید" value={inputs.message} onChange={(e)=>setInputs({...inputs,message:e.target.value})} required></textarea>
            </fieldset>
            <button className="faFont textSize11 contactSubmitBtn flex flexCenter">
                ثبت
            </button>
            {
                inputs.loading && <div className="flex flexCol flexCenter loadingProgressMainDiv">
                    <div className="flex flexCol flexCenter loadingMoreDataSubDiv">
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                </div>
            </div>
            }
        </form>
        <div className="flex flexCol contactLeftDiv">
            <img src="/image/contact.webp" alt="contact us" className="contactLeftImg" draggable="false" width={450}/>
        </div>
    </section>
}
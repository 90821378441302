import React from "react";
import "../Style/videoGrid.css";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import VideoItem from "./VideoItem";
import { Link } from "react-router-dom";

export default function VideoGrid({data,part}){
    return <>
        <div className="flex flexCol videoGridMainDiv">
            <div className="flex alignCenter justifyBetween sectionHeader">
                <h2 className="faFont textSize12 textClr3 rel sectionTitle flex alignCenter gap5">
                    { part.titleLogo }
                    { part.titleText }
                </h2>
                <Link to={part.title} className="faFont textSize1 textClr3 sectionLink flex alignCenter gap5">
                    { part.linkText }
                    { part.linkLogo }
                </Link>
            </div>
            <Swiper className="swiper videoSwiper rel"
                spaceBetween={10}
                slidesPerView={1}
                breakpoints={{
                    649:{
                        slidesPerView: 2,
                    },
                    967:{
                        slidesPerView: 3,
                        spaceBetween : 20
                    },
                }}
            >
                {
                    data.map((item,index)=>{
                        return <SwiperSlide key={index}>
                            <VideoItem data={item} index={part.preTemp + index} hasPlay={true}/>
                    </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    </>
}
import React from "react";
import { Link } from "react-router-dom";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import BlogItem from "./BlogItem";

export default function BlogGrid({data,title,hasMore}){
    return <section className="flex flexCol container homeBlogSection">
        <h3 className="faFont textSize9 textClr3 blogTitle1 rel">
            مقالات
        </h3>
        <div className="flex alignCenter justifyBetween homeBlogHeader">
            <div className="flex flexCol">
                <p className="faFont textClr3 blogTitle2">
                   {
                    title
                   }
                </p>
            </div>
            {
                hasMore && <Link to="/blogs" className="faFont textSize1 textClr3 seeAllBlogs flex alignCenter gap5">
                    همه مقالات
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                </Link>
            }
        </div>
        <Swiper className="swiper blogSwiper rel"
                modules={[Autoplay]}
                slidesPerView={1.08}
                spaceBetween={20}
                centeredSlides="true"
                autoplay={{
                    delay: 5000,
                }}
                breakpoints={{
                    649:{
                        slidesPerView: 2,
                        centeredSlides:false
                    },
                    968:{
                        spaceBetween : 25,
                        slidesPerView: 3,
                        centeredSlides:false
                    },
                }}
            >
                {
                    data.map((item,index)=>{
                        return <SwiperSlide key={index}>
                            <BlogItem data={item} />
                        </SwiperSlide>
                    })
                }
            </Swiper>
    </section>
}
{/* <section className="flex flexCol homeBlogSection container">
                       <div className="flex alignCenter justifyBetween">
                            <h2 className="faFont textSize12 textClr3 rel sectionTitle flex alignCenter gap5">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M446.6 222.7c-1.8-8-6.8-15.4-12.5-18.5-1.8-1-13-2.2-25-2.7-20.1-.9-22.3-1.3-28.7-5-10.1-5.9-12.8-12.3-12.9-29.5-.1-33-13.8-63.7-40.9-91.3-19.3-19.7-40.9-33-65.5-40.5-5.9-1.8-19.1-2.4-63.3-2.9-69.4-.8-84.8.6-108.4 10C45.9 59.5 14.7 96.1 3.3 142.9 1.2 151.7.7 165.8.2 246.8c-.6 101.5.1 116.4 6.4 136.5 15.6 49.6 59.9 86.3 104.4 94.3 14.8 2.7 197.3 3.3 216 .8 32.5-4.4 58-17.5 81.9-41.9 17.3-17.7 28.1-36.8 35.2-62.1 4.9-17.6 4.5-142.8 2.5-151.7zm-322.1-63.6c7.8-7.9 10-8.2 58.8-8.2 43.9 0 45.4.1 51.8 3.4 9.3 4.7 13.4 11.3 13.4 21.9 0 9.5-3.8 16.2-12.3 21.6-4.6 2.9-7.3 3.1-50.3 3.3-26.5.2-47.7-.4-50.8-1.2-16.6-4.7-22.8-28.5-10.6-40.8zm191.8 199.8l-14.9 2.4-77.5.9c-68.1.8-87.3-.4-90.9-2-7.1-3.1-13.8-11.7-14.9-19.4-1.1-7.3 2.6-17.3 8.2-22.4 7.1-6.4 10.2-6.6 97.3-6.7 89.6-.1 89.1-.1 97.6 7.8 12.1 11.3 9.5 31.2-4.9 39.4z"></path></svg>
                                مقالات
                            </h2>
                            <Link to="/teachers" className="faFont textSize1 textClr3 sectionLink flex alignCenter gap5">
                                همه مقالات
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                            </Link>
                        </div>
                        <div className="grid homeBlogsGrid">
                            {
                                data.blogs.map((item,index)=>{
                                    return <div className="grid homeBlogItem" key={index}>
                                        <Link to={item.url} className="flex flexCol homeBlogImgLink">
                                            <img src={item.cover} alt={item.title} className="homeBlogImg" />
                                        </Link>
                                        <div className="flex flexCol homeBlogInfoDiv">
                                            <h3 className="faFont textSize12 textClr3 homeBlogName">
                                                {
                                                    item.title
                                                }
                                            </h3>
                                            <p className="faFont textSize9 textClr5 homeBlogBody">
                                            {
                                                item.body
                                            }
                                            </p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </section> */}